@charset "utf-8";
/* CSS Document */

@import "_forms";
@import "_slideshow";

/* base styles */

body {
    background:#50403a;
}

h1 {
	font-family: "proxima-nova";
    font-size:30px;
    font-weight:700;
    text-transform:uppercase;
    color:#50403a;
    margin:0;
}

h1.internal {
	font-size:50px;
    line-height:45px;
    margin-bottom:10px;
}

@media screen and (max-width: 500px) {

     h1.internal {
        font-size:40px;
        line-height:38px;
    }
}

h2 {
	font-family: "proxima-nova";
    font-size:26px;
    font-weight:700;
    color:#50403a;
    margin:0;
}

h2.internal {
	color:#d05a46;
}

p {
	font-family: "source-sans-pro";
    font-size:15px;
    line-height:22px;
    color:#50403a;
}

p a {
	color: #f45237;
    text-decoration:none;
}

p a:hover {
	color: #d94127;
    transition:0.25s;
    -webkit-transition:0.25s;
}

ul {
	font-family: "source-sans-pro";
    font-size:15px;
    line-height:22px;
    color:#50403a;
	margin: 18px 0 0 0;
}

@media screen and (max-width: 320px) {

     ul {
        margin-left:-18px;
    }
}


ul li a {
    color: #f45237;
    text-decoration:none;
}

ul li a:hover {
	color: #d94127;
    transition:0.25s;
    -webkit-transition:0.25s;
}

a.home {
    font-family: "proxima-nova";
    font-size:27px;
    color:#FFF;
    font-weight:700;
    text-transform:uppercase;
    text-align:center;
    text-decoration:none;
    margin:30px 0 50px 0;
    padding:22px 50px;
    border:1px solid #FFF;
}

a.home:hover {
    color:#de5d47;
    transition:0.5s;
    border:1px solid #de5d47;
}

@media screen and (max-width: 500px) {

    a.home {
        font-size:22px;
        font-weight:700;
        margin:30px 0 50px 0;
        padding:15px 30px;
    }

}

@media screen and (max-width: 320px) {

    a.home {
        font-size:18px;
        font-weight:700;
        padding:10px 20px;
        margin-bottom:30px;
    }

}

a.button {
    font-family: "proxima-nova";
    font-size:18px;
    color:#FFF;
    background-color:#d05a46;
    font-weight:700;
    text-transform:uppercase;
    text-align:center;
    text-decoration:none;
    padding:15px 30px;
    display:block;
}

a.button:hover {
    background:#b4422f;
    color:#FFF;
}

/* site structure */

html,
body {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    /* iOS fix */
    -webkit-overflow-scrolling: touch;
}

#pageWrapper {
    left: 0;
    position: relative;
    /* css3 */
    -webkit-transition: left 0.25s;
    transition: left 0.25s;
    width:100%;
    box-sizing:border-box;
}

@media screen and (max-width: 935px) {
    #mobileNavToggle:checked + #pageWrapper {
        left: -220px;
    }
}

#homeWrapper {
    background: #50403a;
	padding: 0 5px;
    z-index:1;
}

#home {
    max-width:1200px;
    height:auto;
	clear: both;
    margin:0 auto;
    padding:60px 0;
    z-index:2;
    box-sizing:border-box;
}

@media screen and (max-width: 1200px) {

    #home {
        padding:50px 30px;
    }
}

@media screen and (max-width: 500px) {

    #home {
        padding:30px 20px;
    }
}

    #home h1 {
        font-family: "proxima-nova";
        font-size:30px;
        font-weight:700;
        text-transform:uppercase;
        color:#f2dccc;
        margin:0;
    }

    #home p {
        width:50%;
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:22px;
        color:#FFF;
    }

#feature {
    float:right;
    width:40%;
    background:#FFF;
    margin-top:-100px;
    z-index:9;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
}

    #feature img {
        width:100%;
        height:auto;
        margin-bottom:15px;
    }


    #feature h3 {
        font-family: "proxima-nova";
        font-size:30px;
        font-weight:700;
        text-transform:uppercase;
        color:#2a1b14;
        margin:0;
        padding:0 20px;
    }

    #feature h5 {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:22px;
        color:#f45237;
        font-style:italic;
        text-transform:uppercase;
        font-weight:400;
        margin:0;
        padding:0 20px;
    }

    #feature p {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:22px;
        width:90%;
        color:#281913;
        padding:0 20px 25px 20px;
        margin:0;
        margin-top:10px;
    }

    #feature a {
        font-family: "proxima-nova";
        font-size:15px;
        font-weight:500;
        text-transform:uppercase;
        color:#f45237;
        text-decoration:none;
        background:#2a1b14;
        display: block;
        padding:20px 20px;
    }

    #feature a:hover {
        color:#FFF;
        background:#120905;
        transition:0.25s;
        -webkit-transition:0.25s;
    }

@media screen and (max-width: 935px) {

    #feature {
        display:none;
    }

    #home p {
        width:100%;
    }
}

#mainWrapper {
	background: #f2dccc;
	padding: 0 5px;
}

main {
	max-width:1200px;
    width:100%;
    height:auto;
    overflow: hidden;
	clear: both;
    margin:0 auto;
    padding:60px 0;
    box-sizing:border-box;
}

@media screen and (max-width: 1200px) {

    main {
        padding:60px 30px;
    }

}

@media screen and (max-width: 500px) {

    main {
        padding:30px 20px;
    }

}

    main .home-intro {
        float:left;
        width:50%;
        height:auto;
    }

    main .home-intro h1 {
        font-size:50px;
        margin-top:20px;
    }

    @media screen and (max-width: 500px) {

        main .home-intro h1 {
            font-size:40px;
            margin-top:20px;
            line-height:38px;
        }

    }

    main .house-slideshow {
        float:right;
        width:40%;
        height:auto;
    }

    main .house-slideshow img {
        width:100%;
        height:auto;
    }

    @media screen and (max-width: 950px) {

        main .home-intro {
            float:none;
            width:100%;
        }

        main .house-slideshow {
            float:none;
            width:100%;
            margin-top:25px;
        }

    }


    #content-full {
        width:100%;
        height:auto;
        box-sizing:border-box;
    }



        #content-full h2 {
            font-family: "proxima-nova";
            font-size:30px;
            font-weight:700;
            text-transform:uppercase;
            color:#2a1b14;
            margin:0;
        }

        #content-full p {
            font-family: "source-sans-pro";
            font-size:15px;
            line-height:22px;
            color:#281913;
        }

    .sub.a,
    .sub.b,
    .sub.c {
        float:left;
        width:30%;
        background:#FFF;
        margin:30px 0 0;
        margin-right:5%;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

    .sub.d {
        float:right;
        width:30%;
        margin:30px 0 0;
        background:#FFF;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

    @media screen and (max-width: 935px) {

        .sub.a,
        .sub.c{
            width:48%;
            float:left;
            margin:0;
            margin-bottom:25px;
        }

        .sub.b,
        .sub.d{
            width:48%;
            float:right;
            margin:0;
            margin-bottom:25px;
        }

        .sub.a,
        .sub.b {
            margin-top:30px;
        }

    }

    @media screen and (max-width: 650px) {

        .sub.a,
        .sub.b,
        .sub.c,
        .sub.d {
            width:100%;
            float:none;
            margin-bottom:25px;
        }

    }

        .sub.last img,
        .sub img {
            width:100%;
            height:auto;
            padding-bottom:0px;
        }

        .sub.last h3,
        .sub h3 {
            font-family: "proxima-nova";
            font-size:17px;
            font-weight:700;
            text-transform:uppercase;
            color:#2a1b14;
            margin:0;
            padding:0 20px;
        }

        .sub.last h5,
        .sub h5 {
            font-family: "source-sans-pro";
            font-size:15px;
            line-height:22px;
            color:#f45237;
            font-style:italic;
            font-weight:400;
            margin:0;
            padding:0 20px;
        }

        .sub.last p,
        .sub p {
            font-family: "source-sans-pro";
            font-size:15px;
            line-height:22px;
            color:#281913;
            padding:0 20px 20px;
        }

        .sub.last a,
        .sub a {
            font-family: "proxima-nova";
            font-size:15px;
            font-weight:500;
            text-transform:uppercase;
            color:#f45237;
            text-decoration:none;
            background:#2a1b14;
            display: block;
            padding:20px 20px;
        }

        .sub.last a:hover,
        .sub a:hover {
            color:#FFF;
            background:#120905;
            transition:0.25s;
            -webkit-transition:0.25s;
        }

    #models-left {
        float:left;
        width:48%;
        background:#f45237;
        margin:30px 0 0;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

    #models-right {
        float:right;
        width:48%;
        background:#f45237;
        margin:30px 0 0;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

    #models-left img,
    #models-right img {
        width:100%;
        height:auto;
    }

    #models-left a,
    #models-right a {
        font-family: "proxima-nova";
        font-size:18px;
        font-weight:500;
        text-transform:uppercase;
        color:#f45237;
        text-decoration:none;
        background:#2a1b14;
        display: block;
        padding:20px 20px;
    }

    #models-left a:hover,
    #models-right a:hover {
        color:#FFF;
        background:#120905;
        transition:0.25s;
        -webkit-transition:0.25s;
    }

    @media screen and (max-width: 635px) {

        #models-left,
        #models-right {
            width:100%;
            float:none;
            margin-bottom:25px;
        }

    }

    #custom.a {
        float:left;
        width:30%;
        background:#2a1b14;
        margin:30px 0 0;
        margin-right:5%;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

    #custom.b {
        float:left;
        width:30%;
        background:#2a1b14;
        margin:30px 0 0;
        margin-right:2.6666667%;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

    #custom.c {
        float:right;
        width:30%;
        margin:30px 0 0;
        background:#2a1b14;
        -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    }

        #custom.a h3,
        #custom.b h3,
        #custom.c h3 {
            font-family: "proxima-nova";
            font-size:18px;
            font-weight:700;
            text-transform:uppercase;
            color:#2a1b14;
            margin:0;
            padding:0 20px;
            margin-bottom:10px;
        }

        #custom.a img,
        #custom.b img,
        #custom.c img {
            width:100%;
            height:auto;
        }

        #custom.a a,
        #custom.b a,
        #custom.c a {
            font-family: "proxima-nova";
            font-size:18px;
            font-weight:500;
            text-transform:uppercase;
            color:#f45237;
            text-decoration:none;
            background:#2a1b14;
            display: block;
            padding:20px 20px;
        }

        #custom.a a:hover,
        #custom.b a:hover,
        #custom.c a:hover {
            color:#FFF;
            background:#120905;
            transition:0.25s;
            -webkit-transition:0.25s;
        }

        @media screen and (max-width: 768px) {

            #custom.a,
            #custom.b,
            #custom.c {
                float:none;
                width:100%;
                margin:20px 0 20px;
            }

        }


@media screen and (max-width: 768px) {

    main {
        padding:30px 5% 30px 5%;
    }

    #primary {
        width:100%;
        float:none;
        padding:0;
        margin-bottom:30px;
    }

    #posts {
        width:100%;
        float:none;
        padding:0;
        margin-bottom:30px;
    }



}

#floorplanWrapper {
	background: #FFF;
	padding: 0 5px;
}

#floorplan {
	max-width:1200px;
    height:auto;
    overflow: hidden;
	clear: both;
    margin:0 auto;
    padding:60px 0;
    box-sizing:border-box;
}

#floorplan img {
	width:90%;
    height:auto;
    margin-left:5%;
    margin-top:20px;
    padding-bottom:20px;
}

#floorplan .map {
    margin: 20px auto 0;
    width: 100%;
}

@media screen and (max-width: 1200px) {

    #floorplan {
        padding:60px 30px;
    }

}

@media screen and (max-width: 500px) {

    #floorplan {
        padding:30px 20px;
    }

}

    #floorplan .slides {
        width:40%;
        height:auto;
        float:left;
        margin-right:5%;

    }

    #floorplan .slides img {
        width:100%;
        height:auto;
        padding-bottom:20px;
    }

    #floorplan .text {
        width:55%;
        height:auto;
        float:right;
    }

    @media screen and (max-width: 950px) {

        #floorplan .slides {
            width:100%;
            float:none;
            margin-bottom:30px;
        }

        #floorplan .text {
            width:100%;
            float:none;
            margin-top:10px;
        }

    }

#featuresWrapper {
	background: #d05a46;
	padding: 0 5px;
}

#features {
	max-width:1200px;
    height:auto;
    overflow: hidden;
	clear: both;
    margin:0 auto;
    padding:60px 0;
}

@media screen and (max-width: 1200px) {

    #features {
        padding:60px 30px;
    }

}

@media screen and (max-width: 500px) {

    #features {
        padding:30px 20px;
    }

}

    #features h2 {
        color:#ffffff;
    }

    #features p {
        color:#FFF;
        margin:5px 0;
    }

    #features ul.left {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:22px;
        color:#FFF;
        width:26.5%;
        margin-right:2.5%;
        float:left;
    }

    #features ul.center {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:22px;
        color:#FFF;
        width:26.5%;
        margin-left:2.5%;
        margin-right:2.5%;
        float:left;
    }

    #features ul.right {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:22px;
        width:26.5%;
        color:#FFF;
        margin-left:2.5%;
        float:right;
    }

    #features .energy-star {
        float:right;
        width:30%;
        height:auto;
    }

    #features .energy-star img {
        float:right;
        margin-left:15px;
    }

    #features .energy-star p em {
        color:#ff9f8f;
        float:right;
        margin-top:0px;
        line-height:18px;
        text-align:right;
    }

@media screen and (max-width: 1200px) {

    #features ul.left {
        width:24%;
        margin-right:5%;
    }

    #features ul.center {
        width:24%;
        margin-left:2.5%;
        margin-right:2.5%;
    }

    #features ul.right {
        width:24%;
        margin-left:2.5%;
    }

}

@media screen and (max-width: 825px) {

    #features .energy-star {
        display:none;
    }

}

@media screen and (max-width: 850px) {

    #features ul.left {
        width:22%;
        margin-right:7%;
    }

    #features ul.center {
        width:22%;
        margin-left:2.5%;
        margin-right:2.5%;
    }

    #features ul.right {
        width:22%;
        margin-left:2.5%;
    }

}

@media screen and (max-width: 700px) {

    #features ul.left {
        width:20%;
        margin-right:7%;
    }

    #features ul.center {
        width:20%;
        margin-left:2.5%;
        margin-right:2.5%;
    }

    #features ul.right {
        width:20%;
        margin-left:2.5%;
    }

}

@media screen and (max-width: 650px) {

    #features ul.left {
        width:100%;
        float:none;
        margin:0;
    }

    #features ul.center {
        width:100%;
        float:none;
        margin:0;
    }

    #features ul.right {
        width:100%;
        float:none;
        margin:0;
    }

}

@media screen and (max-width: 320px) {
	#features ul.left {
        margin-left:-18px;
    }

    #features ul.center {
        width:100%;
        float:none;
        margin-left:-18px;
    }

    #features ul.right {
        width:100%;
        float:none;
        margin-left:-18px;
    }
}

#footerWrapper {
	background: #50403a;
}

footer {
    max-width:1200px;
    width:100%;
    padding:40px 0;
    height:auto;
    margin:0 auto;
    box-sizing:border-box;
}

    #financing {
        width:35%;
        height:auto;
        float:left;
    }

    #financing img {
        float:left;
        margin-right:30px;
        margin-top:7px;
    }

    #financing p {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:20px;
        color:#FFF;
        margin:0;
    }

    #financing p em {
        color:#aa8d83;
    }

    #sitemap {
        width:55%;
        height:auto;
        float:right;
    }

    #sitemap p.sitemap {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:20px;
        color:#FFF;
        margin:0;
        float:left;
    }

    #sitemap p.sitemap a {
        text-decoration:none;
        color:#FFF;
    }

    #sitemap p.sitemap a:hover {
        text-decoration:none;
        color:#CCC;
    }

    #sitemap p em {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:20px;
        color:#aa8d83;
    }

    #sitemap h3 {
        color: #f2dccc;
        text-decoration: none;
        font-family: "proxima-nova";
        font-size: 22px;
        font-weight:700;
        float:right;
        margin:0;
    }

    #mobile {
        display:none;
    }


@media screen and (max-width: 1200px) {

    footer {
        padding:50px 30px;
    }

    #financing {
        width:30%;
    }

    #sitemap {
        width:65%;
    }
}

@media screen and (max-width: 1120px) {

    #financing {
        width:20%;
    }

    #financing img {
        display:none
    }

    #sitemap {
        width:75%;
    }

}

@media screen and (max-width: 935px) {

    #financing {
        display:none;
    }

    #sitemap {
        display:none;
    }

    footer {
        padding:20px 0 30px;
    }

    #mobile {
        display:block;
        width:100%;
        text-align:center;
    }

    #mobile h3 a {
        color: #f2dccc;
        text-decoration: none;
        font-family: "proxima-nova";
        font-size: 22px;
        font-weight:700;
        margin:0;
    }

    #mobile p {
        font-family: "source-sans-pro";
        font-size:15px;
        line-height:20px;
        color:#FFF;
        margin:0;
    }

    #mobile img {
        padding:10px 0 10px;
    }

    #mobile hr {
        border:none;
        border-bottom:1px solid #aa8d83;
        padding:10px 0 10px;
        width:70%;
    }

}

/* breakpoints */

@media screen and (max-width: 1280px) {
	/* landscape desktops */
}

@media screen and (max-width: 1024px) {
	/* landscape 10" tablets */
}

@media screen and (max-width: 960px) {
	/* landscape 7" tablets */
}

@media screen and (max-width: 853px) {
	/* landscape 6" tablets */
}

@media screen and (max-width: 768px) {
	/* portrait 10" tablets */
}

@media screen and (max-width: 640px) {
	/* portrait 7" tablets */
}

@media screen and (max-width: 480px) {
	/* landscape phones */
}

@media screen and (max-width: 320px) {
	/* portrait phones */
}



/* IE Snap Fix */

@-ms-viewport {
    width: device-width;
}

table {
	color: #FFF;
	font-family: "source-sans-pro";
    font-size:15px;
	font-weight: normal;
	line-height: 22px;
	margin: 20px 0 20px;
}



table {
	display: table;
	width: 100%;
}


	table td,
	table th {
		padding: 7px 10px;
		vertical-align: top;
	}

    table tr:nth-child(even) td {
		background: #e6725f;
	}



	table thead td,
	table th {
		color: #FFF;
		font-weight: 700;
		text-align: left;
        background:#50403a;
	}

img.abt {
    float:right;
    margin-top:10px;
    width:48%;
    height:auto;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22);
}

@media screen and (max-width: 650px) {

    img.abt {
        width:100%;
        float:none;
        margin-bottom:10px;
    }

}

input[type=submit] {
    font-family: "proxima-nova";
    font-size:18px;
    color:#FFF;
    background-color:#d05a46;
    font-weight:700;
    text-transform:uppercase;
    text-align:center;
    text-decoration:none;
    padding:15px 30px;
    display:block;
    border:none;
}

input[type=submit]:hover {
    background:#b4422f;
    color:#FFF;
}

.g-recaptcha {
    margin:20px 0 20px;
}

.-block {
    display: block;
    float: none !important;
}

.slideshow-wrapper {
    width: 49.5%;
    max-width: 500px;
}
.slideshow-wrapper .house-slideshow {
    width: 100%;
    max-width: 500px;
}
.slideshow-wrapper .slideshow-after {
    width: 100%;
}
.slideshow-wrapper .button {
    width: 100%;
    padding: 15px 0;
}
@media screen and (max-width: 991px) {
    .slideshow-wrapper {
        width: 100%;
    }
}

.alignleft {
    float: left;
}

.alignright {
    float: right;
}

@media screen and (max-width: 991px) {    
    .alignleft,
    .alignright {
        float: none;
    }
}
