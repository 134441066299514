.slideshow {
    position: relative;
}

    .slideshow:before {
        content: "\0020";
        display: block;
        height: 0;
        padding-bottom: 75%;
        width: 100%;
    }

    .slideshow menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }

        .slideshow menu button {
            background-color: transparent;
            background-position: center center;
            background-repeat:  no-repeat;
            border: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            text-indent: -999em;
            top: 0;
            width: 60px;
            z-index: 3;
        }

        .slideshow menu li:first-child button {
            background-image: url("../media/arrow-left-sm.png");
            left: 0;
        }

        .slideshow menu li:last-child button {
            background-image: url("../media/arrow-right-sm.png");
            right: 0;
        }

    .slideshow figure {
        bottom: 0;
        left: 0;
        opacity: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.25s;
        z-index: 1;
    }

    .slideshow figure.active {
        opacity: 1;
        z-index: 2;
    }

        .slideshow figure img {
            display: block;
            width: 100%;
        }

        .slideshow figure figcaption {
            background: #000;
            background: rgba(0,0,0,0.75);
            bottom: 0;
            left: 0;
            padding: 5px 10px;
            position: absolute;
            right: 0;
        }

            .slideshow figure figcaption h6 {
                color: #FFF;
                font-family: "source-sans-pro";
                font-size:15px;
                font-weight:400;
                padding:10px;
                margin: 0;
            }
