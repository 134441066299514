@charset "UTF-8";
/* CSS Document */

#weblinx {
	background: #FFF;
	border: 2px solid #CCC;
	color: #000000;
	font: normal 11px "Trebuchet MS", Arial, sans-serif;
	margin: 10px auto;
	padding: 5px;
	text-align: center;
	width: 230px;
	/* css3 */
	filter: alpha(opacity=10);
	-webkit-opacity: 0.10;
	-o-opacity: 0.10;
	-ms-opacity: 0.10;
	-moz-opacity: 0.10;
	opacity: 0.10;
	-webkit-transition: opacity .25s;
	-o-transition: opacity .25s;
	-ms-transition: opacity .25s;
	-moz-transition: opacity .25s;
	transition: opacity .25s;
}

	#weblinx:hover {
		cursor: pointer;
		/* css3 */
		filter: alpha(opacity=100);
		-webkit-opacity: 1;
		-o-opacity: 1;
		-ms-opacity: 1;
		-moz-opacity: 1;
		opacity: 1;		
	}

	#weblinx a:link, #weblinx a:visited  {
		color: #000000;
		text-decoration: none;
	}

		#weblinx a:hover, #weblinx a:active {
			color: #000000;
		}