/* forms */

::-webkit-input-placeholder {
	color: #9f9f9f;
}

:-moz-placeholder {
	color: #9f9f9f;
}

::-moz-placeholder {
	color: #9f9f9f;
}

:-ms-input-placeholder {
	color: #9f9f9f;
}

.placeholder {
	color: #9f9f9f;
}


input[type=checkbox],
input[type=radio] {
	left: -999em;
	position: absolute;
}

	input[type=checkbox] + label,
	input[type=radio] + label {
		position: relative;
		padding: 13px 0 13px 59px;
	}

	@media screen and (max-width: 639px) {
		input[type=checkbox] + label,
		input[type=radio] + label {
			padding-left: 54px;
		}
	}

	input[type=checkbox] + label:before,
	input[type=radio] + label:before {
		background: #FFF;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 22px;
		border: 1px solid #CBCBCB;
		content: "\0020";
		display: block;
		float: left;
		height: 44px;
		left: 1px;
		position: absolute;
		top: 1px;
		width: 44px;
	}

	@media screen and (max-width: 639px) {
		input[type=checkbox] + label:before,
		input[type=radio] + label:before {
			background-size: 18px;
			height: 40px;
			width: 40px;
		}
	}

	input[type=checkbox] + label:hover:before,
	input[type=radio] + label:hover:before {
		cursor: pointer;
	}

	input[type=checkbox]:checked + label:before {
		background-image: url("../media/icon-checkbox.png");
	}

	input[type=radio] + label:before {
		/* css3 */
		-webkit-border-radius: 22px;
		border-radius: 22px;
	}

	input[type=radio]:checked + label:before {
		background-image: url("../media/icon-radio.png");
	}

	input[type=checkbox] + label:after,
	input[type=checkbox] + label:after {
		clear: both;
		content: "\0020";
		display: block;
		visibility: hidden;
		zoom: 1;
	}

input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
textarea {
	color: #393939;
	font-family: "source-sans-pro";
	font-size: 15px;
	font-weight: 500;
    background: #FFF;
	border: 1px solid #CBCBCB;
	box-sizing: border-box;
	display: block;
	margin: 0 0 10px;
	outline: none;
	padding: 7px 15px;
	text-overflow: ellipsis;
	width: 100%;
}

label {
	display: block;
	margin: 0 0 10px;
}

label:hover {
	cursor: pointer;
}

select {
	background-image: url("../media/icon-select.png");
	background-position: right 15px center;
	background-repeat: no-repeat;
	background-size: auto 16px;
	padding: 0;
}

select:hover {
	cursor: pointer;
}

	select {
		display: block;
		outline: none;
		padding: 9px 11px;
		text-indent: 0.01px;
    	text-overflow: "";
		width: 100%;
		/* css3 */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	select ::-ms-expand {
		display: none;
	}

	select:hover {
		cursor: pointer;
	}

		select option {
			background: #FFF;
			color: #393939;
		}

		select option:hover {
			background: #DDD;
		}

textarea {
	max-width: 100%;
	min-height: 100px;
	min-width: 100%;
}
