@charset "utf-8";
/* CSS Document */

/* CSS3 Responsive Navigation v2.0 built by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

#bar {
    background:#d05a46;
    width:100%;
    height:6px;
}

#headerWrapper {
    background:url("../media/header.jpg") top center no-repeat #29160e;
	padding: 0 5px;
}

header {
    max-width:1200px;
    width:100%;
    height:auto;
    margin:0 auto;
    box-sizing:border-box;
}

    @media screen and (max-width: 1200px) {

        header {
            padding:0 30px;
        }

    }

    @media screen and (max-width: 500px) {

        header {
            padding:0 20px;
        }

    }

    header img {
        float:left;
        padding:20px 0 20px;
        position: relative;
        z-index: 2;
    }

    @media screen and (max-width: 500px) {

        header img {
            max-width:140px;
            height:auto;
        }

    }

    @media screen and (max-width: 320px) {

        header img {
            max-width:100px;
            height:auto;
        }

    }

    .phone {
        float:right;
        padding:60px 0;
    }

    .phone h3 a {
        color: #de5d47;
        text-decoration: none;
        font-family: "proxima-nova";
        font-size: 27px;
        font-weight:700;
    }

    .callout {
        width:95%;
        margin-left:2.5%;
        height:auto;
        margin-top:60px;
        padding-bottom:110px;
        text-align:center;
    }

    .callout h2 {
        font-family: "proxima-nova";
        font-size:55px;
        line-height:52px;
        color:#FFF;
        font-weight:700;
        text-transform:uppercase;
        text-align:center;
        margin:0;
    }

    .callout h3 {
        font-family: "source-sans-pro";
        font-size:28px;
        line-height:44px;
        color:#de5d47;
        font-weight:100;
        font-style:italic;
        text-align:center;
        margin:0 0 60px 0;
    }



    @media screen and (max-width: 1200px) {

        #home {
            padding:0 30px;
        }

    }

    @media screen and (max-width: 935px) {

        .callout {
            width:100%;
            margin:30px 0 0;
        }

        .callout h2 {
            font-size:45px;
        }

        .callout h3 {
            font-size:24px;
        }
    }

    @media screen and (max-width: 555px) {
        .callout h2 {
            font-size:35px;
            line-height:38px;
        }

        .callout h3 {
            font-size:20px;
            line-height:24px;
        }
    }


    @media screen and (max-width: 935px) {

        .phone {
            display:none;
        }

        .phone p,
        .phone p a {
            font-size:30px;
        }

    }

    @media screen and (max-width: 800px) {

        .phone p,
        .phone p a {
            font-size:24px;
        }

    }

    @media screen and (max-width: 600px) {

        .phone p,
        .phone p a {
            display:none;
        }

    }

	header > label {
		content: "\0020";
        cursor: pointer;
		display: none;
		float: right;
		height: 60px;
		margin-top: 30px;
		padding: 19px 8px;
		position: relative;
		width: 44px;
	}

	@media screen and (max-width: 935px) {
		header > label {
			display: block;
		}
	}

    @media screen and (max-width: 935px) {
		header > label {
			height: 44px;
		}
	}

    @media screen and (max-width: 320px) {
		header > label {
			margin:5px 0 20px;
		}
	}

        header > label:after,
        header > label:before,
        header > label em {
            border-top: 4px solid #d05a46;
            content: "\0020";
            display: block;
            height: 0;
            left: 15px;
            overflow: hidden;
            position: absolute;
            right: 15px;
            top: 70%;
        }

        @media screen and (max-width: 320px) {

            header > label:after,
            header > label:before,
            header > label em {
                top: 65%;
            }
        }


            header > label:after {
                margin-top: 7px;
            }

            header > label:before {
                margin-top: -13px;
            }

            header > label em {
                height: 0;
                margin-top: -3px;
            }

    @media screen and (max-width: 935px) {
        header > nav {
            display: none;
        }
    }

nav {
	float: right;
	position: relative;
	width: 68%;
    padding-bottom:20px;
	z-index: 999;
}

@media screen and (max-width: 1200px) {
	nav {
		width: 60%;
        padding-right:20px;
	}

    .phone h3 a {
        font-size: 24px;
    }
}

@media screen and (max-width: 935px) {
	nav {
		background: none;
		float: none;
	}
}

	nav label, nav input[type=checkbox] {
		display: none;
	}

	nav ul {
		float: right;
		list-style: none;
		margin: 0 auto;
        margin-top:72px;
		padding: 0;
		position: relative;
		right: 45%;
		text-align: center;
		width: auto;
		z-index: 999;
	}

	@media screen and (max-width: 935px) {
		nav ul {
			clear: both;
			float: none;
			height: auto;
			max-height: 0;
			overflow: hidden;
			right: auto;
			width: 100%;
			visibility: hidden;
			/* css3 */
			-webkit-transition: max-height 0.5s, visibility 0s 0.5s !important;
			transition: max-height 0.5s, visibility 0s 0.5s !important;
		}

		nav input[type=checkbox]:checked + ul {
			max-height: 2000px;
			visibility: visible;
			/* css3 */
			-webkit-transition: max-height 1s, visibility 0s 0s !important;
			transition: max-height 1s, visibility 0s 0s !important;
		}
	}

		nav ul:after {
			clear: both;
			content: "\0020";
			display: block;
			visibility: hidden;
			zoom: 1;
		}

		nav ul li {
			background-image: none;
			float: left;
			left: 50%;
			position: relative;
			/* css3 */
			-webkit-transition: background 00.25s;
			transition: background 00.25s;
		}

		@media screen and (max-width: 935px) {
			nav ul li {
				float: none;
				left: auto;
                width:100%;
			}
		}



		@media screen and (max-width: 935px) {
			nav ul li:hover {
				background: none;
			}
		}

			nav ul li:before {
				display: none;
			}

			nav ul li a {
                font-family: "source-sans-pro";
				font-size: 18px;
				font-weight:100;
                color:#FFF;
                text-decoration:none;
				display: block;
				padding: 8px 20px;
			}

			@media screen and (max-width: 935px) {
				nav ul li a {
					padding: 12px 15px;
				}
			}

			nav > ul > li > a:hover,
			nav > ul > li:hover > a {
                color:#de5d47;
                transition:0.25s;
                -webkit-transition:0.25s;
			}

			nav ul li label {
				display: none;
				height: 43px;
				position: absolute;
				right: 0;
				top: 0;
				width: 43px;
			}

			@media screen and (max-width: 935px) {
				nav ul li label {
					display: block;
				}
			}

				nav ul li label:after,
				nav ul li label:before {
					background: #53b848;
					content: "\0020";
					display: block;
					height: 6px;
					position: absolute;
					top: 50px;
					width: 18px;
				}

				nav ul li label:after {
					right: 8px;
					/* css3 */
					-webkit-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}

				nav ul li label:before {
					left: 8px;
					/* css3 */
					-webkit-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
				}

				nav ul li label em {
					left: -9999em;
					position: absolute;
				}

			/* sub list */

			nav ul li ul {
				background: #CECECE;
				left: -999em;
				opacity: 0;
				position: absolute;
				text-align: left;
				top: 100%;
				width: 200px;
				/* css3 */
				-webkit-transition: opacity 0.25s 0s, left 0s 0.25s;
				transition: opacity 0.25s 0s, left 0s 0.25s;
			}

			@media screen and (max-width: 935px) {
				nav ul li ul {
					left: auto !important;
					max-height: 0;
					opacity: 1;
					overflow: hidden;
					position: relative;
					text-align: center;
					top: auto;
					visibility: hidden;
					width: 100%;
				}
			}

			nav ul li:hover > ul {
				left: 0;
				opacity: 1;
				/* css3 */
				-webkit-transition: opacity 0.25s 0s, left 0s 0s;
				transition: opacity 0.25s 0s, left 0s 0s;
			}

				nav ul li ul li {
					float: none;
					left: auto;
					width: 100%;
				}

				@media screen and (max-width: 935px) {
					nav ul li ul li:last-of-type {
						border-bottom: 0;
					}
				}

				nav ul li ul li:hover {
				}

					nav ul li ul li a {
					}

					nav > ul > li > ul > li > a:hover,
					nav > ul > li > ul > li:hover > a {
					}

					/* sub sub list */

					nav ul li ul li ul {
						background: #B9B9B9;
						top: 0;
						width: 250px;
					}

					@media screen and (max-width: 935px) {
						nav ul li ul li ul {
							width: 100%;
						}
					}

					nav ul li ul li:hover > ul {
						left: 100%;
					}

						nav ul li ul li ul li {
						}

						nav ul li ul li ul li:hover {
						}

							nav ul li ul li ul li a {
							}

							nav ul li ul li ul li a:hover {
							}

@media screen and (max-width: 1200px) {
	nav ul li a {
        font-size: 15px;
        padding:8px 15px;
    }
}

#mobileNavToggle {
    position: absolute;
    left: -999em;
}

#mobileNavWrapper {
    background: #50403a;
    bottom: 0;
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    width: 220px;
    z-index: 999;
    /* css3 */
    -webkit-transition: margin-left 0.25s;
    transition: margin-left 0.25s;
}

@media screen and (max-width: 935px) {
    #mobileNavWrapper {
        display: block;
    }
}

    #mobileNavWrapper #mobileNav {
    }



        #mobileNavWrapper #mobileNav .phoneMobile {
            float:left;
            margin:7px 0 20px 15px;
        }

        #mobileNavWrapper #mobileNav .phoneMobile h3 a {
            color: #f2dccc;
            text-decoration: none;
            font-family: "proxima-nova";
            font-size: 22px;
            font-weight:700;
            float:right;
            margin:0;
        }

        #mobileNavWrapper #mobileNav nav {
        }

            #mobileNavWrapper #mobileNav nav ul {
                max-height: none;
                visibility: visible;
            }

                #mobileNavWrapper #mobileNav nav ul li {
                }

                    #mobileNavWrapper #mobileNav nav ul li a {
                        color: #d05a46;
                        font-size:17px;
                        width:100%;
                        text-align:left;
                    }
